import './css/uikit-rtl.css';
import './css/uikit-rtl.min.css';
import './css/uikit.css';
import './css/uikit.min.css';
import './App.css';

import React, { useState, useEffect } from "react"
import axios from 'axios';
import Swal from 'sweetalert2';

import Logo from "../src/img/varlik-platformu.svg"
import Icon1 from "../src/img/icons/fi_2893811.svg"
import Icon2 from "../src/img/icons/fi_10108175.svg"
import Icon3 from "../src/img/icons/fi_1077114.svg"
import Icon4 from "../src/img/icons/fi_1161439.svg"
import Icon5 from "../src/img/icons/fi_13965236.svg"
import Icon6 from "../src/img/icons/fi_15894949.svg"
import Icon7 from "../src/img/icons/fi_17855857.svg"
import Icon8 from "../src/img/icons/fi_2893811.svg"
import Icon9 from "../src/img/icons/fi_3803544.svg"
import Icon10 from "../src/img/icons/fi_4415847.svg"
import Icon11 from "../src/img/icons/fi_471713.svg"
import Icon12 from "../src/img/icons/fi_4774280.svg"
import Icon13 from "../src/img/icons/fi_5405826.svg"
import Icon14 from "../src/img/icons/fi_5585562.svg"
import Icon15 from "../src/img/icons/fi_5585562.svg"
import Icon16 from "../src/img/icons/fi_s_2893811.svg"
import Pattern from "../src/img/icons/patern.svg"

import Frame581 from "../src/img/frame581.png"
import Bonus from "../src/img/bonus.png"

import Comments1 from "../src/img/comments/1.png"
import Comments2 from "../src/img/comments/2.png"
import Comments3 from "../src/img/comments/3.png"
import Comments4 from "../src/img/comments/4.png"
import Comments5 from "../src/img/comments/5.png"
import Comments6 from "../src/img/comments/6.png"
import Comments7 from "../src/img/comments/7.png"
import Comments8 from "../src/img/comments/8.png"
import Comments9 from "../src/img/comments/9.png"
import Comments10 from "../src/img/comments/10.png"
import Comments11 from "../src/img/comments/11.png"

import Stories1 from "../src/img/stories/1.png"
import Stories2 from "../src/img/stories/2.png"
import Stories3 from "../src/img/stories/3.png"

import ThankYouImg from "../src/img/bitcoin_7403709.svg"

import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';

function App() {
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getRandomProduct() {
        const products = ["USDJPY", "GBPUSD", "EURCHF", "WTI", "USDTRY", "AUDUSD", "XAUUSD", "EURUSD", "DE30", "AUDCAD"];
        return products[getRandomInt(0, products.length - 1)];
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0'); // Gün
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay (0'dan başladığı için +1)
        const year = date.getFullYear(); // Yıl
        return `${day}.${month}.${year}`; // DD.MM.YYYY formatı
    }

    const items = [];
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate()); // Bugünkü tarih

    for (let i = 0; i < 50; i++) {
        const item = {
            date: formatDate(currentDate),
            product: getRandomProduct(),
            lot: "1 LOT",
            guarantee: `${getRandomInt(200, 2000)}`,
            transaction: getRandomInt(0, 1) === 0 ? "BUY" : "SELL",
            pip: `${getRandomInt(10, 300)}`,
            profit: `${getRandomInt(200, 2000)}`
        };

        items.push(item);

        // Her 4 öğeden sonra tarihi 1 gün geri al
        if ((i + 1) % 4 === 0) {
            currentDate.setDate(currentDate.getDate() - 1);
        }
    }

    const url = window.location
    const params = new URLSearchParams(url.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fullName = name.trim();
        const phoneNumber = phone.trim();

        if (phoneNumber.length < 10) {
            Swal.fire({
                title: "Eksik Bilgi",
                text: "Lütfen telefon numaranızı eksiksiz olarak girin.",
                icon: "warning"
            });
            return;
        }

        // İsim ve Soyisim ayrıştırması
        const nameArray = fullName.split(' ');
        const lastName = nameArray.pop(); // Soyad
        const firstName = nameArray.join(' '); // Geri kalan adlar

        const userData = {
            firstName: firstName,
            lastName: lastName,
            email: 'lp@varlikplatformu.co',  // Sabit email
            phoneNumber: phoneNumber,
            UtmSource: utmSource ? utmSource : ".",
            UtmMedium: utmMedium ? utmMedium : "."
        };

        try {
            const response = await axios.post('https://varlikplatformu.co/api/Form/SubmitForm', userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setIsSubmitted(true);
        } catch (error) {
            Swal.fire({
                title: "Hata",
                text: "Bir hata oluştu. Lütfen tekrar deneyin.",
                icon: "error"
            });
        }
    };
    return (
        <>
            {
                isSubmitted ?
                    <section className="welcome-header">
                        <header className="uk-container uk-container-large">
                            <nav>
                                <img src={Logo} alt="Varlık Platformu Logo" />
                            </nav>
                        </header>
                        <div className="thank-you-card">
                            <img src={ThankYouImg} alt="thankyou" width="75px" />
                            <h5 style={{ fontSize: 32, fontWeight: 500, color: "#2D2CBE" }}>Form Başarıyla Gönderilmiştir.</h5>
                            <p style={{ fontSize: 16 }}>Doldurduğunuz form başarıyla iletilmiştir. Sizi en kısa sürede bilgilendireceğiz.</p>
                            <button className='button' onClick={() => setIsSubmitted(false)}>Ana Sayfaya Dön</button>
                        </div>
                    </section>
                    :
                    <>
                        <section className="welcome">
                            <header className="uk-container uk-container-large">
                                <nav>
                                    <img src={Logo} alt="Varlık Platformu Logo" />
                                </nav>
                            </header>
                            <div className="uk-container uk-container-large">
                                <h1><b>Hemen Kayıt Olun!</b> <br /> Ücretsiz <b>finansal eğitimlerimize özel indirimler</b> ve ücretsiz başlangıç rehberleri ile yatırım bilginizi geliştirin!
                                </h1>
                                <div className="uk-grid-collapse uk-child-width-1-2@m uk-margin" uk-grid>
                                    <div className="uk-flex-last@m">
                                        <div className="form" id="form">
                                            <form id="registrationForm" onSubmit={handleSubmit}>
                                                <div className="last-users">
                                                    <div className="first">
                                                        <img alt="Yıldız" src={Icon1} />
                                                        <b className="son-12-k">SON 12 KİŞİ!</b>
                                                    </div>
                                                    <div className="last">SINIRLI KONTENJAN</div>
                                                </div>
                                                <div className="f-group">
                                                    <b className="title">Finansal Eğitimlerimize Katılmak için Formu Doldurun!</b>
                                                    <div className="f-container">
                                                        <div className="uk-inline">
                                                            <span className="custom-icon">
                                                                <img src={Icon3} alt="Adınız soyadınız" />
                                                            </span>
                                                            <input onChange={(e) => setName(e.target.value)} className="uk-input" type="text" name="fullName" placeholder="Adınız soyadınız" required />
                                                        </div>
                                                        <div className="uk-inline">
                                                            <span className="custom-icon">
                                                                <img src={Icon14} alt="Telefon Numaranız" />
                                                            </span>
                                                            <InputMask
                                                                mask="(599) 999 9999"
                                                                maskChar="_"
                                                                value={phone}
                                                                onChange={(e) => setPhone(e.target.value.replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("_", ""))}
                                                            >
                                                                {() => (
                                                                    <input className="uk-input" type="tel" name="phoneNumber" placeholder="Telefon Numaranız" required />
                                                                )}
                                                            </InputMask>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="button">Hemen Kaydol</button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="benefits">
                                        <h2>FİNANSAL BİLGİNİZİ GELİŞTİRME FIRSATINI KAÇIRMAYIN!</h2>
                                        <p>Neden Bu Eğitim Programlarıyla Bilginizi Geliştirmelisiniz?</p>
                                        <ul>
                                            <li><img src={Icon7} alt="Analizler" /> Uzman analizlerle doğru yatırımları belirleyin ve kârlı kararlar alın.</li>
                                            <li><img src={Icon13} alt="Yüksek başarı oranı" />Başarı oranı yüksek stratejilerle kazancınızı maksimize edin.</li>
                                            <li><img src={Icon2} alt="Aracı kurum" />Finansal yolculuğunuzda uzman desteğiyle her adımda kendinizi güvende hissedin.</li>
                                            <li><img src={Icon12} alt="Destek ve direnç" />Destek ve direnç noktalarını analiz ederek sağlam yatırım kararları alın.</li>
                                            <li><img src={Icon10} alt="Eğitimler" />Başlangıç seviyesinden ileriye, bilginizi artırarak güvenle yatırım yapmaya başlayın!
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="customer-review">
                            <div className="uk-container uk-container-large campaigns-container">
                                <div className="cover">
                                    <img alt="Nihat Bey" src={Bonus} />
                                </div>
                                <div className="review-text">
                                    <h2>Yatırımda İlk Adımınızı Avantajla Atın!</h2>
                                    <p>
                                        Eğitimimizi tamamlayan katılımcılar, finansal bilgilerini derinleştirmenin yanı sıra <span style={{ fontWeight: 800, color: "#33E146" }}>Anlaşmalı Kurumlarımızda</span> özel <span style={{ fontWeight: 800, color: "#33E146" }}>Hoş Geldin Bonusu</span> elde etme fırsatı yakalarlar. Bu bonus, yatırımlarınızda güçlü bir başlangıç yapmanıza ve birikimlerinizi avantajlı bir şekilde değerlendirmenize katkı sağlar. Eğitim alın, bilgilerinizi güçlendirin ve özel fırsatlarla yatırım dünyasına sağlam bir adım atın!
                                    </p>
                                </div>

                            </div>
                        </section>
                        <section className="why-us">
                            <div className="uk-container uk-container-large uk-margin-large-bottom">
                                <h2>Neden Bu Finansal Eğitim Programına Katılmalısınız?</h2>
                                <div className="uk-child-width-1-3@m boxes" uk-grid>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon5} />
                                            </div>
                                            <div className="content">
                                                <h3>Kanıtlanmış Yüksek Başarı Oranı</h3>
                                                <p>Her ay %87 başarı oranına ulaşan analizlerle, finansal hedeflerinize bir adım daha yaklaşın. Uzmanlarımızın kazandıran stratejileriyle güvenle yatırım yapmayı öğrenin ve kazanç fırsatlarını değerlendirin.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon6} />
                                            </div>
                                            <div className="content">
                                                <h3>Derinlemesine Piyasa Analizleri</h3>
                                                <p>Uzman analizleriyle piyasa hareketlerini öğrenin ve bilinçli yatırım adımları atın. Her bilgi seviyesine uygun anlık piyasa öngörüleriyle stratejinizi geliştirerek finansal hedeflerinize ulaşmada avantaj sağlayın.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon2} />
                                            </div>
                                            <div className="content">
                                                <h3>Güvenilir Aracı Kurum Rehberliği</h3>
                                                <p>Varlık Platformu’nun test ettiği güvenilir platformlarla yatırım yapmayı öğrenin ve güvenle adım atın. Doğru seçimlerle finansal hedeflerinize hızlı ve güvenli bir şekilde ulaşın.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon10} />
                                            </div>
                                            <div className="content">
                                                <h3>Ücretsiz Risk Yönetimi Eğitimi</h3>
                                                <p>Yatırımlarınızda karşılaşabileceğiniz riskleri en aza indirmenin yollarını keşfedin. Ücretsiz risk yönetimi eğitimimizle, stratejilerinizi sağlam bir temele oturtarak güvenle ilerleyin.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon9} />
                                            </div>
                                            <div className="content">
                                                <h3>Global Piyasalarda Uyumlu Stratejiler</h3>
                                                <p>İngiltere ve Amerika gibi önde gelen finans merkezlerinin regülasyonlarına uygun analizlerle, uluslararası piyasalarda güvenle işlem yapmayı öğrenin. Global uyumlu stratejilerle sınırları aşan bir yatırım deneyimi kazanın.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='why-card'>
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className="icon">
                                                <img alt="" src={Icon4} />
                                            </div>
                                            <div className="content">
                                                <h3>Bilgi Güvenliği ve Gizlilik Garantisi</h3>
                                                <p>Tüm kişisel bilgileriniz ve eğitim süreciniz boyunca paylaştığınız veriler güvencemiz altında. Varlık Platformu, verilerinizi titizlikle korur ve güvenli bir öğrenim ortamı sunar.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a className="cta uk-text-center" href="#form">Ücretsiz Eğitim İçeriğinize Erişin!</a>
                                </div>
                            </div>
                        </section>
                        <section className="customer-review">
                            <div className="uk-container uk-container-large">
                                <div className="review-text">
                                    <p>"Varlık Platformu’na katıldığımda finansal eğitim programları sayesinde piyasa analizlerini öğrenme fırsatı buldum. Uzman analizlerinin yüksek başarı oranı sayesinde kısa sürede finansal bilgi birikimimi artırarak bilinçli adımlar atmaya başladım. Bir süre eğitim aldıktan sonra gerçek piyasada işlem yapmaya başladım. Artık evde veya dışarıda, telefonumdan küçük yatırımlar yaparak düzenli kazanç sağlayabiliyorum. Emekli bir mühendis olarak, bu sistemin bana güven verdiğini söyleyebilirim."</p>
                                    <p className="author"><b>Nihat Bey, Emekli Mühendis</b></p>
                                </div>
                                <div className="cover">
                                    <img alt="Nihat Bey" src={Frame581} />
                                </div>
                            </div>
                        </section>
                        <section className="analyses">
                            <div className="uk-container uk-container-large">
                                <h2>Analiz Başarı Performansımız</h2>
                                <div className="table-content">
                                    <table cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th className="mb-none">TARİH</th>
                                                <th>ÜRÜN</th>
                                                <th className="mb-none">LOT</th>
                                                <th className="mb-none">TEMİNAT</th>
                                                <th className="mb-none">İŞLEM</th>
                                                <th className="mb-none">PİP HAREKETİ</th>
                                                <th className="mb-none">KÂR/ZARAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items.map((e) => (
                                                    <tr>
                                                        <td className="mb-none">{e.date}</td>
                                                        <td className="cat">{e.product}</td>
                                                        <td className="mb-none">{e.lot}</td>
                                                        <td className="mb-none">${e.guarantee}</td>
                                                        <td className="mb-none">{e.transaction}</td>
                                                        <td className="mb-none">{e.pip} PİP</td>
                                                        <td className="price-td">
                                                            <div className="price">{e.profit} <strong>$</strong></div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="analyses-info uk-container uk-container-large">
                                <div className="icon-wrapper">
                                    <img alt="" src={Icon11} />
                                </div>
                                <div className="info-text">
                                    <span>Bu tablo, geçtiğimiz ay grubumuzdaki yatırımcıların uzman analizlerimizle gerçekleştirdiği
                                        işlemlerin sonuçlarını göstermektedir. Siz de benzer sonuçlar elde etmek için </span>
                                    <b>hemen ücretsiz demo hesabınızı açın.</b>
                                </div>
                            </div>
                        </section>
                        <section className="performance">
                            <div className="uk-container uk-container-large">
                                <div className="title-rw">
                                    <h2>Geçen Ayın En İyi Performans Gösteren Ürünleri</h2>
                                </div>
                                {/*title end*/}
                                <div className="prods-list">
                                    <ul>
                                        <li>
                                            <h3>USDTRY</h3>
                                            <p>%92 başarı ile 34 öneri</p>
                                            <p>Toplam: <b>39.792$</b> kâr</p>
                                        </li>
                                        <li>
                                            <h3>EURUSD</h3>
                                            <p>%86 başarı ile 24 öneri</p>
                                            <p>Toplam: <b>26.231$</b> kâr</p>
                                        </li>
                                        <li>
                                            <h3>GBPUSD</h3>
                                            <p>%64 başarı ile 13 öneri</p>
                                            <p>Toplam: <b>14.298$</b> kâr</p>
                                        </li>
                                        <li>
                                            <h3>ALTIN</h3>
                                            <p>%84 başarı ile 18 öneri</p>
                                            <p>Toplam: <b>38.450$</b> kâr</p>
                                        </li>
                                        <li>
                                            <h3>PETROL</h3>
                                            <p>%82 başarı ile 21 öneri</p>
                                            <p>Toplam: <b>32.405$</b> kâr</p>
                                        </li>
                                        <li>
                                            <h3>NASDAQ</h3>
                                            <p>%69 başarı ile 9 öneri</p>
                                            <p>Toplam: <b>9.112$</b> kâr</p>
                                        </li>
                                    </ul>
                                </div>
                                {/*prods-list end*/}
                            </div>
                        </section>
                        <section className="comments">
                            <div className="container">
                                <div className="image-wrapper">
                                    {/* <img src={Comments1} alt="Görsel 1" /> */}
                                    <img src={Comments2} alt="Görsel 2" />
                                    <img src={Comments3} alt="Görsel 3" />
                                    <img src={Comments4} alt="Görsel 4" />
                                    {/* <img src={Comments5} alt="Görsel 5" /> */}
                                    <img src={Comments6} alt="Görsel 6" />
                                    <img src={Comments7} alt="Görsel 7" />
                                    <img src={Comments8} alt="Görsel 8" />
                                    <img src={Comments9} alt="Görsel 9" />
                                    <img src={Comments10} alt="Görsel 10" />
                                    <img src={Comments11} alt="Görsel 10" />
                                </div>
                            </div>
                        </section>
                        <section className="stories">
                            <div className="uk-container uk-container-large">
                                <h2>Grubumuz Sayesinde Başarıya İmza Atanlar</h2>
                                <div className="uk-child-width-1-3@m uk-grid-small uk-grid-match s-boxes" style={{ display: "flex" }} uk-grid>
                                    <div>
                                        <div className="uk-card  uk-card-body storie">
                                            <img className="storie-img" alt="" src={Stories1} />
                                            <div>
                                                <h3>Ali’nin Başarı Hikayesi</h3>
                                                <p>"Piyasalarda hiç deneyimim yoktu, ancak Varlık Platformu’na katıldığımda ücretsiz başlangıç eğitimleriyle adım attım. İlk hafta öğrendiklerimi uygulayarak sanal ortamda kazanç sağladım ve ardından gerçek yatırımlara geçiş yaptım. Geçtiğimiz ay 6.000$ kazanç elde ettim! Her gün uzman analizlere güvenerek işlemler yapıyorum ve bu bana düzenli kazanç sağlıyor. İyi ki bu eğitim programına katılmışım!"</p>
                                            </div>
                                            <div className="stars">
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card  uk-card-body storie">
                                            <img className="storie-img" alt="" src={Stories2} />
                                            <div>
                                                <h3>Murat’ın Başarı Hikayesi</h3>
                                                <p>Finansal piyasalar bana hep karmaşık görünürdü. Ancak Varlık Platformu’nun ücretsiz eğitimlerine katıldım ve kısa sürede piyasayı anlamaya başladım. Burada öğrendiğim bilgiler ve analizlerle geçen ay tam 4.200$ kazanç sağladım. Artık her adımımı bu eğitim programından edindiğim bilgiler doğrultusunda atıyorum. Varlık Platformu sayesinde kendime güvenim arttı ve düzenli kazanç elde ediyorum.</p>
                                            </div>
                                            <div className="stars">
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card  uk-card-body storie">
                                            <img className="storie-img" alt="" src={Stories3} />
                                            <div>
                                                <h3>Aslı’nın Başarı Hikayesi</h3>
                                                <p>"Vadesiz hesabımda tuttuğum yaklaşık 10.000$’lık birikimimi hep faizle değerlendirirdim, ancak Varlık Platformu'nun uzman rehberliğiyle piyasalara adım attım. Bu karar sayesinde yatırımımdan %65 getiri elde ettim; faizle kazanacağımdan çok daha fazlasını sağladım ve artık piyasa dinamiklerini daha iyi anlıyorum. Gerçekten iyi ki katılmışım!"</p>
                                            </div>
                                            <div className="stars">
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                                <img className="star" alt="yıldız" src={Icon16} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer>
                                    <div className="logo">
                                        <img src={Logo} alt="Varlık Platformu Logo" />
                                    </div>
                                    <div className="cta-text">
                                        Ücretsiz Destek Alın!
                                    </div>
                                    <div className="cta-info">
                                        Ücretsiz piyasa analizleri ve eğitimlerimizden faydalanmak için formu
                                        doldurmanız yeterli !
                                    </div>
                                    <a className="cta uk-margin-large-top" href="#form">Hemen Kaydol</a>
                                    <p className="copyright">Tüm Hakları Saklıdır © 2024 Varlık Platformu</p>
                                </footer>
                            </div>
                        </section>
                    </>
            }
        </>
    );
}

export default App;
